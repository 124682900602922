import React, { useState } from "react"
import styled from "@emotion/styled"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Autoplay, Thumbs } from "swiper"
import { getSrc } from "gatsby-plugin-image"
import "./ligthBox.css"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "swiper/css/thumbs"

const LightBoxImg = styled.img`
  @media (min-width: 1024px) {
    width: auto;
    height: 75vh;
  }
`
const ThumbsSwiper = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 1% auto;
  @media (min-width: 576px) {
    width: auto;
    height: 16vh;
  }
`

const LightBoxContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
`

const LightBox = ({
  alt = "Lightbox image",
  zIndex = 10,
  srcLigthBox,
  setIsOpen,
  isOpen,
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  return (
    <div>
      {isOpen ? (
        <LightBoxContainer>
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
              fontSize: "24px",
              color: "white",
              margin: "15px 30px",
              zIndex: 999,
              fontWeight: 900,
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(!isOpen)}
          >
            x
          </div>
          <Swiper
            loop={true}
            lazy={true}
            slidesPerView={1}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Thumbs]}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            className="ligthbox-swiper"
          >
            {srcLigthBox.map((element, key) => (
              <SwiperSlide key={key}>
                <LightBoxImg src={getSrc(element)} alt={alt} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            lazy={true}
            onSwiper={setThumbsSwiper}
            spaceBetween={20}
            breakpoints={{
              360: { slidesPerView: 2.3 },
              460: { slidesPerView: 2.7 },
              600: { slidesPerView: 3.3 },
              768: { slidesPerView: 3.6 },
            }}
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            modules={[Thumbs]}
            className="thumbs-ligthbox-swiper"
          >
            {srcLigthBox.map((element, key) => (
              <SwiperSlide key={key}>
                <ThumbsSwiper src={getSrc(element)} alt={alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        </LightBoxContainer>
      ) : null}
    </div>
  )
}

export default LightBox
