import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Container = styled.div`
  display: inline-flex;
  flex-wrap: wrap;

  div {
    display: inline-flex;
    flex-wrap: wrap;
    p {
      font-family: Test Söhne;
      font-size: 16px;
      line-height: 24px;
      color: #132f60;
      mix-blend-mode: normal;
      margin: 0px 4px;
      white-space: nowrap;
    }
    p.circle-bold {
      font-size: 12px;
    }
  }

  div:first-of-type .circle-bold {
    display: none;
  }
`
const RoomDetails = ({ details }) => {
  return (
    <Container>
      {details.map((detail, index) => (
        <div key={index}>
          <p className="circle-bold">&#9679;</p>
          <p>{detail}</p>
        </div>
      ))}
    </Container>
  )
}

RoomDetails.propTypes = {}

export default RoomDetails
