import styled from "@emotion/styled"

const MapStatus = styled.h1`
  width: 100%;
  align-self: center;
  justify-self: center;
  text-align: center;
`

export { MapStatus }
