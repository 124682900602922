import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import Arrow from "../../images/button-arrow.svg"
import RoomDetails from "./roomDetails"
import { getSrc } from "gatsby-plugin-image"
import { AnimatedButton } from "../customStyledComponents"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 298px;
  & > a {
    text-decoration: none;
  }
  @media (min-width: 576px) {
    width: 452px;
  }
`

const Title = styled.p`
  font-family: Test Söhne;
  font-size: 24px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  word-wrap: break-word;
  padding-top: 16px;
`
const Description = styled.p`
  word-wrap: break-word;
  font-family: Test Söhne;
  font-size: 16px;
  line-height: 24px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-bottom: 32px;
`

const DetailsContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
`

const Button = styled(AnimatedButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  border: 1px solid #132f60;
  box-sizing: border-box;
  background-color: #ffebed;
  text-transform: uppercase;
  width: fit-content;
  cursor: pointer;
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  /*  margin: 1px; */

  img {
    margin-bottom: 0;
    margin-left: 8px;
    width: 10px;
    height: 7.5px;
  }

  /*   &:hover {
    border: 2px solid #ffffff;
    margin: 0px;
  } */
`
const ImgContainer = styled.div`
  width: 100%;
  height: 291px !important;

  img {
    height: 100%;
  }
`
const RoomCard = ({
  image,
  title,
  description,
  details,
  url,
  children,
  subImages,
  setSrcLigthBox,
  setIsOpen,
}) => {
  const src = getSrc(image)
  return (
    <Container>
      <div>
        <ImgContainer>
          <img src={src} className="swiper-lazy" />
          <div className="swiper-lazy-preloader"></div>
        </ImgContainer>

        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <DetailsContainer>
          <RoomDetails details={details} />
        </DetailsContainer>
      </div>
      <a href={url} target="_blank">
        <Button>
          Book now
          <img src={Arrow} />
        </Button>
      </a>
    </Container>
  )
}

RoomCard.propTypes = {}

export default RoomCard
