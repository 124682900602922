import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import ArrowWhite from "../../images/arrow-white-right.svg"
import ArrowBlack from "../../images/arrow-black-right.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { AnimatedButton } from "../customStyledComponents"

const Grid = styled.div`
  display: grid;
  max-width: 100%;
  width: 100%;
  min-height: 400px;
  background-color: ${props =>
    props.backColor ? props.backColor : "transparent"};
  color: ${props => (props.color ? props.color : "#000000")};
  flex-grow: ${props => (props.fwl || props.fwr || props.center ? "1" : "0")};
  flex: ${props => (props.flex ? "1" : "auto")};

  @media (min-width: 576px) {
    max-width: ${props =>
      props.fwl || props.fwr || props.center ? "100%" : "480px"};
    min-height: 458px;
  }
`

const GridTextContent = styled.div`
  grid-area: 1/1;
  display: flex;
  flex-direction: column;
  padding: 88px 32px;
  @media (min-width: 576px) {
    padding: 72px;
  }

  align-items: ${props =>
    props.fwl ? "flex-end" : props.center ? "center" : "flex-start"};

  /*   &.fullWidth {
    flex-grow: 1;
width: 100%;
  } */
`

const TextContainer = styled.div`
  // width - padding-x
  max-width: 408px;
`
const GridChildContent = styled.div`
  grid-area: 1/1;
  position: relative;
  pointer-events: none;
`
const GridImageContent = styled.div`
  grid-area: 1/1;
  position: relative;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => (props.fwl ? `right` : `left`)};
  mix-blend-mode: multiply;
  .gatsby-image-wrapper div:first-of-type {
    padding-top: unset !important;
  }

  /*   height: 400px;

  @media (min-width: 576px) {
    height: 458px;
  } */
`
const Header = styled.p`
  font-family: Test Söhne;
  font-size: 16px;
  line-height: 24px;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-bottom: 16px;
  text-transform: uppercase;
  word-wrap: anywhere;
`

const Title = styled.div`
  font-family: Test Söhne;
  font-size: 32px;
  line-height: 40px;
  mix-blend-mode: normal;
  margin: 0px 0px;
  word-wrap: anywhere;
  padding-bottom: 16px;
`

const Description = styled.div`
  font-family: Test Söhne;
  font-size: 16px;
  line-height: 24px;
  mix-blend-mode: normal;
  margin: 0px 0px;
  word-wrap: anywhere;
`

const Link = styled.a`
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  width: fit-content;
  display: block;
`
const LinkContent = styled(AnimatedButton)`
  display: flex;
  width: fit-content;
  align-items: center;
  p {
    font-family: Test Founders Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: ${props => (props.color ? props.color : "#000000")};
    mix-blend-mode: normal;
    margin: 0px 0px;
    text-transform: uppercase;
    text-decoration: none;
  }
  img {
    margin-left: 8px;
    width: 10px;
    height: 7.5px;
  }
`
const colorWhite = "#FFFFFF"
const CollageTextCard = ({
  backColor,
  color,
  header,
  title,
  description,
  children,
  url,
  linkName,
  fwl,
  fwr,
  center,
  flex,
  image,
}) => {
  return (
    <Grid
      backColor={backColor}
      color={color}
      fwr={fwr}
      fwl={fwl}
      center={center}
      flex={flex}
    >
      <GridTextContent fwr={fwr} fwl={fwl} center={center} flex={flex}>
        <TextContainer>
          <Header>{header}</Header>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Link href={!!url ? url : "#"}>
            <LinkContent color={color}>
              <p>{linkName}</p>
              <img src={color === colorWhite ? ArrowWhite : ArrowBlack} />
            </LinkContent>
          </Link>
        </TextContainer>
      </GridTextContent>
      {children && <GridChildContent>{children}</GridChildContent>}
      {image && (
        <GridImageContent
          fwr={fwr}
          fwl={fwl}
          center={center}
          flex={flex}
          style={{ backgroundImage: `url(${image})` }}
        ></GridImageContent>
      )}
    </Grid>
  )
}

CollageTextCard.propTypes = {}

export default CollageTextCard
