import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Navigation, Autoplay, Thumbs } from "swiper"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slide from "react-reveal/Slide"
import LightBox from "../subcomponents/ligthBox"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/autoplay"

import { Container1440 } from "../customStyledComponents"
import RoomCard from "../subcomponents/roomCard"

import "./sectionRooms.scss"
import LeftArrow from "../../images/left-arrow.svg"
import RightArrow from "../../images/right-arrow.svg"

SwiperCore.use([Pagination, Navigation, Autoplay])

const Container = styled.div`
  background: #ffebed;
`
const Header = styled.p`
  font-family: Test Founders Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  color: #132f60;
  mix-blend-mode: normal;
  margin: 0px 0px;
  text-transform: uppercase;
  padding-bottom: 32px;
`
const ContentContainer = styled(Container1440)`
  padding-left: 32px;
  padding-top: 88px;
  padding-bottom: 88px;
  @media (min-width: 576px) {
    padding-left: 48px;
  }
`

const Spacer = styled.div`
  width: 24px;
`
const SectionRooms = props => {
  const data = useStaticQuery(graphql`
    query {
      room1: file(relativePath: { eq: "room1.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }
      room2: file(relativePath: { eq: "room2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }
      room3: file(relativePath: { eq: "room3.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }
      room4: file(relativePath: { eq: "room4.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 452
            height: 291
            placeholder: BLURRED
          )
        }
      }
    }
  `)

  const items = [
    {
      image: data.room1,
      title: "Balcony Queen",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["2 guests", "1 Night minimum Stay"],
      key: "1",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room2,
      title: "Balcony King & Jacuzzi",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["3 guests", "1 Night minimum Stay"],
      key: "2",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room3,
      title: "Balcony King",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["4 guests", "1 Night minimum Stay"],
      key: "3",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room4,
      title: "Balcony Queen & Jacuzzi",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["5 guests", "1 Night minimum Stay"],
      key: "4",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room1,
      title: "Balcony Queen",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["2 guests", "1 Night minimum Stay"],
      key: "5",
      subImages: [
        data.room2,
        data.room2,
        data.room2,
        data.room2,
        data.room2,
        data.room2,
        data.room2,
        data.room2,
      ],
    },
    {
      image: data.room2,
      title: "Balcony King & Jacuzzi",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["3 guests", "1 Night minimum Stay"],
      key: "6",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room3,
      title: "Balcony King",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["4 guests", "1 Night minimum Stay"],
      key: "7",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
    {
      image: data.room4,
      title: "Balcony Queen & Jacuzzi",
      description: "",
      url: "https://hotels.cloudbeds.com/en/reservation/kQVeDn",
      details: ["5 guests", "1 Night minimum Stay"],
      key: "8",
      subImages: [data.room2, data.room2, data.room2, data.room2],
    },
  ]

  const [isOpen, setIsOpen] = useState(false)
  const [srcLigthBox, setSrcLigthBox] = useState([])

  return (
    <Container>
      <ContentContainer>
        <Header>Rooms</Header>
        <LightBox
          srcLigthBox={srcLigthBox}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <Slide up>
          <Swiper
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            lazy={true}
            pagination={{
              type: "progressbar",
            }}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            slidesPerView={"auto"}
            spaceBetween={32}
            className="roomSwiper"
            breakpoints={{
              756: {
                spaceBetween: 48,
              },
            }}
          >
            {items.map(
              ({ image, title, description, details, url, subImages, key }) => (
                <SwiperSlide key={key}>
                  <RoomCard
                    setIsOpen={setIsOpen}
                    setSrcLigthBox={setSrcLigthBox}
                    image={image}
                    title={title}
                    description={description}
                    url={url}
                    details={details}
                    subImages={subImages}
                  ></RoomCard>
                </SwiperSlide>
              )
            )}
            <div slot="container-end">
              <div className="customSection">
                <div className="prev">
                  <img src={LeftArrow} />
                </div>
                <Spacer />
                <div className="next">
                  <img src={RightArrow} />
                </div>
                {/*  <Spacer /> */}
              </div>
            </div>
          </Swiper>
        </Slide>
      </ContentContainer>
    </Container>
  )
}

SectionRooms.propTypes = {}

export default SectionRooms
