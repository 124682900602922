import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Arrow from "../../images/arrow-green-right.svg"
import { AnimatedLink } from "../customStyledComponents"
import SVGArrow from "../SVGArrow"

const Container = styled.div`
  background: #ffffff;
  border-radius: 2px;
  padding: 24px;
  width: 318px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.p`
  font-family: Test Söhne;
  font-size: 24px;
  line-height: 32px;
  color: #1e1f21;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-bottom: 16px;
`

const Description = styled.p`
  font-family: Test Söhne;
  font-size: 16px;
  line-height: 24px;
  color: #1e1f21;
  mix-blend-mode: normal;
  margin: 0px 0px;
  padding-bottom: 16px;
`
const Link = styled.a`
  text-decoration: none;
  width: fit-content;
`
const LinkContent = styled(AnimatedLink)`
  display: flex;
  width: fit-content;
  align-items: center;
  p {
    font-family: Test Founders Grotesk;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    color: #51bfa5;
    mix-blend-mode: normal;
    margin: 0px 0px;
    text-transform: uppercase;
    text-decoration: none;
  }
  svg {
    margin-left: 8px;
    width: 10px;
    height: 7.5px;
  }
`

const AmenitieCard = ({
  title,
  description,
  image,
  url,
  linkName,
  internal,
}) => {
  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </div>
      <div>
        <Link href={!!url ? url : "#"} target={!!internal ? "_self" : "_blank"}>
          <LinkContent>
            <p>{linkName}</p>
            <SVGArrow color="#51BFA5" width="10px" height="7.5" />
          </LinkContent>
        </Link>

        <GatsbyImage
          style={{
            borderRadius: `2px`,
            marginTop: `16px`,
          }}
          image={image.childImageSharp.gatsbyImageData}
          alt={title}
        />
      </div>
    </Container>
  )
}

AmenitieCard.propTypes = {}

export default AmenitieCard
