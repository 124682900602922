import styled from "@emotion/styled"

export const Container1440 = styled.div`
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`

export const AnimatedButton = styled.div`
  img {
    transition: margin-left ease 300ms;
  }

  &:hover {
    img {
      margin-left: 16px;
    }
  }
`

export const AnimatedLink = styled.div`
  svg {
    transition: margin-left ease 300ms;
  }
  svg > * {
    transition: stroke ease 300ms;
  }
  p {
    transition: color ease 300ms;
  }

  &:hover {
    svg {
      margin-left: 15px;
      color: #229076;
    }
    svg > * {
      stroke: #229076;
    }
    p {
      color: #229076;
    }
  }
`
