import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import CollageTextCard from "../subcomponents/collageTextCard"
import elipse from "../../images/elipse.svg"
import angle from "../../images/angle.svg"
import { Container1440 } from "../customStyledComponents"
import CollageImageCard from "../subcomponents/collageImageCard"
import Elipse from "../../images/elipse.svg"
import Angle from "../../images/angle.svg"
import NoElipseOrange from "../../images/no-elipse-orange.svg"
import NoElipsePink from "../../images/no-elipse.svg"

const Container = styled(Container1440)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (min-width: 1440px) {
    flex-wrap: nowrap;
    max-width: 100%;
  }
`

const ColorBar = styled.div`
  mix-blend-mode: normal;
  height: 18px;
  width: 100%;
  background-color: ${props =>
    props.backColor ? props.backColor : "transparent"};
  @media (min-width: 576px) {
    height: 44px;
  }
`

const ColorBarSpace = styled.div`
  width: 100%;
  flex-grow: 2;
`
const Card1 = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`

const Card3 = styled.div`
  display: flex;
  //opacity: 0.3;

  .card3-img {
    @media (min-width: 1440px) {
      position: absolute;
      z-index: 4;
    }
  }
`

const Card5 = styled.div`
  display: flex;
  //opacity: 0.3;

  img {
    margin: 0px;
  }
`
const contentItems = [
  {
    backColor: "#FFAF50",
    color: "#132F60",
    header: "GOLF COURSE",
    title: "Dorado Beach East Golf Course",
    description:
      "TPC Dorado Beach is home to two world-class Caribbean golf courses.",
    url: "https://goo.gl/maps/mCNVYXS1ncKKGNQZ7",
    linkName: "VIEW LOCATION",
  },
  {
    backColor: "#51A28E",
    color: "#FFFFFF",
    header: "VIENTO LODGE",
    title: "Let us help you plan everything you will need",
    description: "Dorado is known for its world class Golf Courses.",
    url: "/#form",
    linkName: "CONTACT US",
  },
  {
    backColor: "#132F60",
    color: "#FFFFFF",
    header: "MOUNTAIN BIKING",
    title: "Bike through the Dorado Beach Bike Trail",
    description:
      "Lghtly trafficked out and back trail located nearby that is good for all skill levels.",
    url: "https://goo.gl/maps/xCBb9SdpJizCG3ym7",
    linkName: "VIEW TRAIL",
  },
]
const SectionCollage = props => {
  const breakpoints = useBreakpoint()
  return (
    <>
      {breakpoints.collagethree && (
        <>
          <Container>
            <CollageTextCard {...contentItems[0]} fwl>
              <Card1>
                <ColorBarSpace />
                <ColorBar backColor="#51BFA5" />
                <ColorBar backColor="#132F60" />
              </Card1>
            </CollageTextCard>
            <CollageImageCard
              image={
                <StaticImage
                  src="../../images/vientogolf.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
            ></CollageImageCard>
            <CollageTextCard {...contentItems[1]} fwr>
              <Card3>
                <img
                  src={Elipse}
                  style={{
                    width: `300px`,
                    height: `318px`,
                    mixBlendMode: `multiply`,
                  }}
                  className="card3-img"
                />
              </Card3>
            </CollageTextCard>
          </Container>
          <Container>
            <CollageImageCard
              fwl
              image={
                <StaticImage
                  src="../../images/bike.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>
            <CollageTextCard
              {...contentItems[2]}
              image={Angle}
            ></CollageTextCard>
            <CollageImageCard
              fwr
              image={
                <StaticImage
                  src="../../images/vientogolf2.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>
          </Container>
        </>
      )}
      {!breakpoints.collagethree && breakpoints.collagetwo && (
        <>
          <Container>
            <CollageTextCard {...contentItems[0]} fwl flex>
              <Card1>
                <ColorBarSpace />
                <ColorBar backColor="#51BFA5" />
                <ColorBar backColor="#132F60" />
              </Card1>
            </CollageTextCard>
            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/vientogolf.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
            ></CollageImageCard>
          </Container>
          <Container>
            <CollageTextCard {...contentItems[1]} fwl flex>
              <Card3>
                <img
                  src={Elipse}
                  style={{
                    width: `300px`,
                    height: `318px`,
                    mixBlendMode: `multiply`,
                  }}
                  className="card3-img"
                />
              </Card3>
            </CollageTextCard>

            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/bike.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>
          </Container>
          <Container>
            <CollageTextCard
              {...contentItems[2]}
              fwl
              flex
              image={Angle}
            ></CollageTextCard>
            <CollageImageCard
              fwr
              flex
              image={
                <StaticImage
                  src="../../images/vientogolf2.png"
                  formats={["auto", "webp", "avif"]}
                  alt="Bella Surf background"
                  layout="fullWidth"
                  imgStyle={{
                    objectFit: "cover",
                  }}
                  className="coverImage"
                />
              }
              mask={NoElipseOrange}
            ></CollageImageCard>
          </Container>
        </>
      )}
      {!breakpoints.collagetwo && (
        <Container>
          <CollageTextCard {...contentItems[0]} center>
            <Card1>
              <ColorBarSpace />
              <ColorBar backColor="#51BFA5" />
              <ColorBar backColor="#132F60" />
            </Card1>
          </CollageTextCard>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/vientogolf.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                }}
                className="coverImage"
              />
            }
          ></CollageImageCard>
          <CollageTextCard {...contentItems[1]} center>
            <Card3>
              <img
                src={Elipse}
                style={{
                  width: `300px`,
                  height: `318px`,
                  mixBlendMode: `multiply`,
                }}
                className="card3-img"
              />
            </Card3>
          </CollageTextCard>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/bike.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                }}
                className="coverImage"
              />
            }
            mask={NoElipseOrange}
          ></CollageImageCard>
          <CollageTextCard
            {...contentItems[2]}
            center
            image={Angle}
          ></CollageTextCard>
          <CollageImageCard
            center
            image={
              <StaticImage
                src="../../images/vientogolf2.png"
                formats={["auto", "webp", "avif"]}
                alt="Bella Surf background"
                layout="fullWidth"
                imgStyle={{
                  objectFit: "cover",
                }}
                className="coverImage"
              />
            }
            mask={NoElipseOrange}
          ></CollageImageCard>
        </Container>
      )}
    </>
  )
}

SectionCollage.propTypes = {}

export default SectionCollage
