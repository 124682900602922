/* global google */

import React, { useState } from "react"
import { Wrapper } from "@googlemaps/react-wrapper"
import Map from "./Map"
import Marker from "./Marker"
import { MapStatus } from "./GoogleMap.style"
import { useNoRenderRef, usePrevious } from "../../../hooks"
import IconLime from "../../../images/marker-lime.png"
import IconRed from "../../../images/marker-red.png"

const GoogleMap = ({
  markers,
  onMarkerClick,
  activeMarkerId,
  ...mapOptions
}) => {
  const [mapMarkers, setMapMarkers] = useState([])
  const [infoWindow, setInfoWindow] = useState(null)
  const prevInfoWindow = usePrevious(infoWindow)
  const infoWindowRef = useNoRenderRef(infoWindow)

  React.useEffect(() => {
    if (prevInfoWindow) {
      prevInfoWindow.close()
    }
  }, [infoWindow])

  React.useEffect(() => {
    if (markers) {
      const newMarkers = markers.map(marker => {
        if (marker.selected) {
          if (
            infoWindowRef &&
            infoWindowRef?.current?.getPosition().lat() !== marker.position.lat
          ) {
            infoWindowRef?.current?.close()
          }
        }
        return {
          icon: marker.id === -1 ? IconRed : IconLime,
          onClick: handleMarkerClick,
          onSelected: centerInMarker,
          ...marker,
        }
      })

      setMapMarkers(newMarkers)
    }
    //TODO remove markers on unmount
  }, [markers, infoWindowRef])

  const handleMarkerClick = currentMarker => {
    if (infoWindow) {
      infoWindow.close()
    }
    const newInfoWindow = new google.maps.InfoWindow({
      content: `
          <div>
          <p><b>${currentMarker.name}</b></p>
          <p>${currentMarker.phone}</p>
          <p>${currentMarker.address}</p>
          </div>
          `,
    })
    infoWindowRef.current = newInfoWindow
    setInfoWindow(infoWindowRef.current)
    newInfoWindow.open({
      anchor: currentMarker,
      map: currentMarker.getMap(),
      shouldFocus: false,
    })

    onMarkerClick(currentMarker)
    //currentMarker.getMap().panTo(currentMarker.position);
    /* onMarkerClick(currentMarker.id) */
  }

  const renderLoadStatus = status => {
    return <MapStatus>{status}</MapStatus>
  }

  const centerInMarker = marker => {
    marker.getMap().panTo(marker.position)
    /* if (infoWindow && infoWindow.lat !== marker.position.lat) {
      infoWindow.close();
    } */
  }

  return (
    <Wrapper
      apiKey="AIzaSyDGkC8G3CihCS4Clqy5svhspFtculr-t6c"
      render={renderLoadStatus}
    >
      <Map {...mapOptions}>
        {mapMarkers.map(markerProps => (
          <Marker key={markerProps.id} {...markerProps} />
        ))}
      </Map>
    </Wrapper>
  )
}

export default GoogleMap
