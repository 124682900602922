/* global google */
import React from "react"
import { useDeepCompareEffectForMaps } from "../../../hooks"
import { MapContainer } from "./Map.style"
const Map = ({
  className,
  onClick,
  onIdle,
  children,
  style,
  markers,
  ...options
}) => {
  const ref = React.useRef(null)
  const [map, setMap] = React.useState(null)

  /**
   * Handle ref changes
   */
  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  /**
   * Handle events listeners changes
   */
  React.useEffect(() => {
    if (map) {
      ;["click", "idle"].forEach(eventName =>
        google.maps.event.clearListeners(map, eventName)
      )

      if (onClick) {
        map.addListener("click", onClick)
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map))
      }
    }
  }, [map, onClick, onIdle])

  /**
   * Handle map options changes
   */
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  return (
    <>
      <MapContainer ref={ref} className={className} style={style} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

Map.defaultProps = {
  onClick: () => {},
  onIdle: () => {},
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 4,
}

export default Map
