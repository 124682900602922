/* global google */
import React from "react"

const Marker = ({
  id,
  selected,
  icon,
  onClick,
  onSelected,
  map,
  type,
  name,
  address,
  phone,
  position,
  ...options
}) => {
  const [marker, setMarker] = React.useState()

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker())
    }

    return () => {
      if (marker) {
        marker.setMap(null)
        google.maps.event.clearListeners(marker, "click")
      }
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.id = id
      marker.selected = selected
      marker.type = type
      marker.name = name
      marker.phone = phone
      marker.address = address
      marker.setOptions(options)
      marker.setMap(map)
      marker.setIcon({
        url: icon,
        scaledSize: new window.google.maps.Size(30, 50), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(15, 50),
      })
      marker.setPosition(position)
      //update listener
      google.maps.event.clearListeners(marker, "click")
      marker.addListener("click", () => onClick(marker))
      animate(marker, selected)
    }
  }, [marker, id, selected, icon, type, name, phone, position, map, address])

  function animate(currentMarker, active = false) {
    if (!active) {
      currentMarker.setAnimation(null)
    } else {
      onSelected(currentMarker)
      if (!currentMarker.getAnimation()) {
        currentMarker.setAnimation(google.maps.Animation.BOUNCE)
      }
    }
  }

  return null
}

export default Marker
