import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 400px;
  flex-grow: ${props => (props.fwl || props.fwr || props.center ? "1" : "0")};

  flex: ${props => (props.flex ? "1" : "auto")};

  .coverImage {
    min-height: 400px;
    @media (min-width: 576px) {
      height: 100%;
    }
  }

  @media (min-width: 480px) {
    min-width: ${props => (props.flex ? "unset" : "480px")};
  }
  @media (min-width: 576px) {
    max-width: ${props =>
      props.fwl || props.fwr || props.center ? "unset" : "480px"};
    width: ${props =>
      props.fwl || props.fwr || props.center ? "unset" : "100%"};
    max-height: 458px;
  }
`

const Grid = styled.div`
  display: grid;
  width: 100%;
  max-width: 100%;
  min-height: 400px;
  background-color: ${props =>
    props.backColor ? props.backColor : "transparent"};
  color: ${props => (props.color ? props.color : "#000000")};
  flex-grow: ${props => (props.fwl || props.fwr || props.center ? "1" : "0")};
  flex: ${props => (props.flex ? "1" : "auto")};
  isolation: isolate;

  @media (min-width: 576px) {
    max-width: ${props =>
      props.fwl || props.fwr || props.center ? "100%" : "480px"};
    min-height: 458px;
  }
`

const GridMaskContent = styled.div`
  grid-area: 1/1;
  position: relative;
  pointer-events: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${props => (props.fwl ? `right` : `left`)};
  mix-blend-mode: multiply;

  & > img {
    height: 60%;
    object-fit: cover;
    position: absolute;
    mix-blend-mode: multiply;
    width: 100%;
  }
  & > div {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: ${props => (props.fwl ? `right` : `left`)};
    mix-blend-mode: multiply;
    position: absolute;
  }
`
const GridImageContent = styled.div`
  grid-area: 1/1;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .gatsby-image-wrapper div:first-of-type {
    padding-top: unset !important;
  }
`

const CollageImageCard = ({ image, mask, fwl, fwr, center, flex }) => {
  return (
    <Grid fwl={fwl} fwr={fwr} center={center} flex={flex}>
      <GridImageContent>{image}</GridImageContent>
      {mask && (
        <GridMaskContent
          fwr={fwr}
          fwl={fwl}
          center={center}
          flex={flex}
          style={{ backgroundImage: `url(${mask})` }}
        ></GridMaskContent>
      )}
    </Grid>
  )
}

CollageImageCard.propTypes = {}

export default CollageImageCard
