import React from "react"
import PropTypes from "prop-types"
import MapCard from "../subcomponents/mapCard"
import styled from "@emotion/styled"
import FormCard from "../subcomponents/formCard"

const Container = styled.div``

const Col = styled.div`
  width: 50%;

  @media (min-width: ) {
  }
`
const SectionContact = props => {
  return (
    <Container className="container-fluid g-0">
      <div className="row g-0 row-cols-1 row-cols-md-2">
        <div className="col order-2 order-md-1" id="map">
          <MapCard />
        </div>
        <div className="col order-1 order-md-2" id="form">
          <FormCard />
        </div>
      </div>
    </Container>
  )
}

SectionContact.propTypes = {}

export default SectionContact
