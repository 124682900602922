import validator from "validator"

class ValidateFields {
  validateEmail(email) {
    if (validator.isEmpty(email)) {
      return "Please, enter valid email address"
    } else if (!validator.isEmail(email)) {
      return "Please, enter valid email address"
    }
    return false
  }

  validateFirstName(firstname) {
    if (validator.isEmpty(firstname)) {
      return "Please, enter valid First Name"
    } else if (!validator.isLength(firstname, { min: 2 })) {
      return "Please, enter valid First Name"
    }
    return false
  }

  validatePhoneNumber(phone) {
    if (validator.isEmpty(phone)) {
      return "Please, enter valid Phone Number"
    } else if (!validator.isMobilePhone(phone)) {
      return "Please, enter valid Phone Number"
    }
    return false
  }

  validateMessage(message) {
    if (validator.isEmpty(message)) {
      return "Please, enter valid Message"
    } else if (!validator.isLength(message, { min: 12 })) {
      return "Please, enter valid Message"
    }
    return false
  }
}

const validateFields = new ValidateFields()

export { validateFields }
