import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const SVGArrow = props => {
  return (
    <svg
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line y1="5" x2="9" y2="5" stroke={props.color} strokeWidth="2" />
      <path d="M6 1L10 5L6 8.5" stroke={props.color} strokeWidth="2" />
    </svg>
  )
}

SVGArrow.propTypes = {}
SVGArrow.defaultProps = {
  color: "#FFF",
  className: "",
  width: 12,
  height: 12,
}
export default SVGArrow
